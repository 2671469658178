import React from "react"
import { Script } from "gatsby"

export const wrapRootElement = ({ element }) => {
  return (
    <>
      {element}
      <Script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="65163a37-045c-4fec-b0d0-3676cd25d24e"
        async
        data-blockingmode="auto"
        type="text/javascript"
      />
    </>
  )
}
